import React from 'react'
import { awards } from '../../data/data'
import Image from "next/future/image"

const HomeAwards = () => {


    const awardCard = (award, index) => (
        <div className="award-card my-4 d-flex justify-content-center flex-column" key={index}>
            <img src={award.awardImage} alt={award.alt} />
        </div>
    )

    return (
        <>
            <div className="awards-container py-md-3 px-md-5 py-5 px-2">
                <div className="row g-0">
                    <div className="col py-md-5">
                        <h3 className="awards-title text-center">
                            Awards <span className="theme-text"> &</span> Recognition
                        </h3>
                        <div className="awards-sub-title text-center">
                            KonfHub, as a startup, has received awards for creating innovative solutions
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center award-cards g-0 py-3 mt-1 px-md-5 py-md-0 pb-md-5 ">
                    {
                        awards.map(awardCard)
                    }
                </div>
            </div>
            <style>{`
        .awards-container {
            background: #F6F9FC;
        }

        .awards-container .awards-title {
            color: #572148;
            font-weight: 700;
            font-size: 50px;
            font-family: "Nunito";
        }

        .awards-container .awards-title .theme-text {
            color: #FB5850;
        }

        .awards-container .awards-sub-title {
            font-family: 'Nunito';
            font-weight: 400;
            font-size: 22px;
            color: #636363;
        }
        .award-cards{
            gap: 50px;
        }

        .award-card{
            padding: 30px;
            max-width: 100%;
            background: #FFFFFF;
            max-width: 453px;
            width: 390px;
            height: 171px;
            border: 3px solid #FFFFFF;
            box-shadow: 3px 3px 40px rgba(0, 0, 0, 0.2);
            border-radius: 5px;
        }


        /* SM screens */
        @media only screen and (min-width: 480px) and (max-width: 768px) {
            .awards-container .awards-title {
                font-size: 30px;
            }

            .award-cards{
            flex-direction: column;
        }
        .award-card{
            width: 390px;
        }
            .awards-container .awards-sub-title {
                font-size: 16px;
            }
        }

        /* XS screens */
        @media only screen and (max-width: 480px) {
            .awards-container .awards-title {
                font-size: 30px;
            }
            .award-cards{
            flex-direction: column;
        }
        .award-card{
            width: 330px;
        }

            .awards-container .awards-sub-title {
                font-size: 16px;
            }
        }
        @media only screen and (max-width: 350px) {

        .award-card{
            width: 300px;
        }
        }
    `}</style>
        </>
    )
}

export default HomeAwards
